<template>
  <div class="profile-master-wrapper">
    <Breadcrumb :breadcrumbs="breadcrumbs" mainLabelName="Профиль"></Breadcrumb>
    <a-tabs class="tabs">
      <a-tab-pane key="1" tab="Личные данные">
        <TabViewContainer>
          <Loader  v-if="!this.$store.getters.getPersonalDataStatusLoading" />
          <MasterSetPersonalData
            v-if="this.$store.getters.getPersonalDataStatusLoading"
            :personalData="this.$store.getters.getPersonalData"
          />
        </TabViewContainer>
      </a-tab-pane>

      <a-tab-pane key="2" tab="Портфолио">
        <TabViewContainer>
          <Loader  v-if="!this.$store.getters.getMasterPortfolioLoaded" />
          <MasterPortfolio
            v-if="this.$store.getters.getMasterPortfolioLoaded"
            :portfolios="this.$store.getters.getMasterPortfolio"
          ></MasterPortfolio>
        </TabViewContainer>
      </a-tab-pane>

      <a-tab-pane key="3" tab="Тарифный план">
        <TabViewContainer>
          <TariffPlan
            :isHistoryPayments="true"
            :isTariffPlan="true"
          ></TariffPlan>
        </TabViewContainer>
      </a-tab-pane>

      <a-tab-pane key="4" tab="Контакты">
        <TabViewContainer>
          <Loader  v-if="!this.$store.getters.getPersonalData" />
          <AddContact :personalData="this.$store.getters.getPersonalData" />
        </TabViewContainer>
      </a-tab-pane>

      <a-tab-pane key="5" tab="Мои Отзывы">
        <TabViewContainer>
          <Loader  v-if="!this.$store.getters.getPersonalDataStatusLoading" />
          <div
              class="reviews"
              style="margin-top: 17px"
              v-if="this.$store.getters.getPersonalDataStatusLoading"
          >
            <Review
                v-for="review in this.$store.getters.getPersonalData.reviews.data"
                :key="review.id"
                :review="review"
            ></Review>
          </div>
<!--          <div class="pagination">-->
<!--            <a-pagination-->
<!--                :current="currentPageActiveReviews"-->
<!--                :total="this.$store.getters.getTotalMasterWatchedReviews"-->
<!--                :showLessItems="true"-->
<!--                show-less-items-->
<!--                @change="onChangeReviewPagination"-->
<!--            />-->
<!--          </div>-->
        </TabViewContainer>
      </a-tab-pane>

    </a-tabs>
  </div>
</template>

<script>
import MasterSetPersonalData from '@/components/MasterComponents/MasterSetPersonalData.vue'
import AddContact from '@/components/common/AddContact.vue'
import TariffPlan from '@/components/MasterComponents/Tariff/TariffPlan.vue'
import MasterPortfolio from '@/components/MasterComponents/MasterPortfolio.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import Review from '@/components/CustomerComponents/Review.vue'
import Loader from '@/components/common/Loader.vue'

export default {
  mounted () {
    this.$store.dispatch('setPersonalData')
    this.$store.dispatch('setMasterPortfolio', 1)
    this.$store.dispatch('setMasterReviews')
  },
  components: {
    MasterSetPersonalData,
    AddContact,
    TariffPlan,
    MasterPortfolio,
    TabViewContainer,
    Breadcrumb,
    Review,
    Loader
  },
  data () {
    return {
      currentPageActivePortfolio: 1,
      currentPageActiveReviews: 1,
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        },
        {
          id: 2,
          name: 'Профиль',
          link: '/set_profile'
        }
      ]
    }
  },
  methods: {
    onChangeReviewPagination (current) {
      this.currentPageActiveReviews = current
      this.$store.dispatch('setMasterProfile', {
        userUUID: this.userUUID,
        page: this.currentPageActiveReviews
      })
    }
  }
}
</script>

<style>
.profile-master-wrapper {
  background-color: white;
  width: 100%;
  height: 100%;
}
.reviews{
  width: 100%;
  padding: 50px 40% 50px 30px;
  background-color: white;
}

.pagination{
  width: 100%;

  display: flex;
  justify-content: flex-end;

  margin-top: 46px;
}
</style>
