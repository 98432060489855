<template>
  <div class="master_portfolio">
    <div class="master_portfolio__label">Портфолио</div>
    <div class="master_portfolio__image__container">
      <file-big
        v-for="file in portfolios"
        :key="file.id"
        :file="file"
      ></file-big>
    </div>
    <div class="master_portfolio__form">
      <a-button @click="showModal" class="master_portfolio__button">
        <div class="master_portfolio__button__container">
          <img src="@/assets/image/uploadFile.svg" />
          <div class="master_portfolio__button__text">Добавить фото</div>
        </div>
      </a-button>
    </div>
    <div class="master_portfolio__pagination">
      <a-pagination
        :current="currentPageActivePortfolio"
        :total="this.$store.getters.getTotalMasterPortfolios"
        :defaultPageSize="6"
        :showLessItems="true"
        show-less-items
        @change="onChangePortfolioPagination"
      />
    </div>
    <a-modal
      :footer="null"
      :visible="isModalVisible"
      @cancel="showModal"
      :closable="false"
    >
      <PortfolioModal
        v-show="isModalVisible"
        @close="showModal"
      ></PortfolioModal>
    </a-modal>
  </div>
</template>

<script>
import PortfolioModal from '@/components/modal/PortfolioModal.vue'
import FileBig from '@/components/common/FileBig.vue'

export default {
  props: ['portfolios'],
  components: {
    PortfolioModal,
    FileBig
  },

  data () {
    return {
      isModalVisible: false,
      currentPageActivePortfolio: 1
    }
  },
  methods: {
    showModal () {
      this.isModalVisible = !this.isModalVisible
    },
    onChangePortfolioPagination (current) {
      this.currentPageActivePortfolio = current
      this.$store.dispatch(
        'setMasterPortfolio',
        this.currentPageActivePortfolio
      )
    }
  }
}
</script>

<style lang="scss">

//.send-file-button-container {
//  width: 50%;
//}

.master_portfolio{
  width: 100%;
  background-color: white;
  padding: 50px 10% 50px 30px;

  &__image__container {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 35px;
  }
  &__button{
    width: fit-content;
    height: 40px;
    border: 1px solid #b12021;
    &__container {
      padding: 15px 0px;
      color: #b12021;
      height: 40px;
      width: 100%;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }
    &__text {
      margin-left: 20px;
    }
  }

  &__form{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 46px;
  }
}

@media(max-width: 576px) {
  .master_portfolio{
    background-color: white;
    padding: 20px;
    &__label{
      font-size: 18px;
      margin-bottom: 0px;
    }
  }
}

</style>
