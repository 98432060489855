<template>
  <div class="master__set__personal__data">
    <a-form class="master__set__personal__data__container">
      <a-form-item>
        <div class="master__set__personal__data__label">Личные данные</div>
      </a-form-item>
      <a-form-item :validateStatus="statusName" :help="helpName">
        <a-input
          :value="personalForm.name"
          v-on:input="updateFormName($event)"
          size="large"
          placeholder="Имя"
        />
      </a-form-item>

      <a-form-item :validateStatus="statusSurname" :help="helpSurname">
        <a-input
          :value="personalForm.surname"
          v-on:input="updateFormSurname($event)"
          size="large"
          placeholder="Фамилия"
        />
      </a-form-item>
      <a-form-item :validateStatus="statusMiddlename" :help="helpMiddlename">
        <a-input
          :value="personalForm.middlename"
          v-on:input="updateFormMiddlename($event)"
          size="large"
          placeholder="Отчество"
        />
      </a-form-item>

      <a-form-item>
        <div class="master__set__personal__data__description-red">
          По правилам сайта, рекомендуем Вам установить фотографию Вашего лица.
          Практика показывает, что таким образом Вы увеличиваете шанс на
          получение заказа.
        </div>
      </a-form-item>
      <a-form-item>
        <UploadAvatar
          name="Загрузить Фото"
          :image="personalForm.image"
          @setAvatar="unloadFile"
        ></UploadAvatar>
      </a-form-item>
      <a-form-item>
        <a-textarea
          :value="personalForm.description"
          v-on:input="updateDescription($event)"
          placeholder="Расскажите о себе"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          class="master__set__personal__data__text-area"
        />
      </a-form-item>
<!--      <a-form-item :validateStatus="statusWorkProfile" :help="helpWorkProfile">-->
<!--        <a-select-->
<!--          placeholder="Профиль работ"-->
<!--          size="large"-->
<!--          @change="updateFormWorkProfile"-->
<!--          showArrow-->
<!--          :default-value="getCurrentWorkTagsValue()"-->
<!--        >-->
<!--          <a-select-option v-for="i in getWorkTagsValue()" :key="i.id">-->
<!--            {{ i.name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-item>-->

      <!-- <InputTags
          v-bind:statusWorkProfile="validationFields.statusWorkProfile"
          v-bind:helpWorkProfile="validationFields.helpWorkProfile"
          v-bind:currentSubtags="personalForm.workProfileValue"
          @transferWorkProfileValue="changeWorkProfile"
          @transferrCheckingWorkProfileForm="changeCheckingWorkProfile"
        ></InputTags> -->

      <a-form-item
        :validateStatus="statusExepirience"
        :help="helpExepirience"
      >
        <a-select
          placeholder="Стаж работы"
          size="large"
          @change="updateFormExepirience"
          :value="personalForm.workExperience"
        >
          <a-select-option value="менее года">менее года</a-select-option>
          <a-select-option value="от года до трех">
            от года до трех
          </a-select-option>
          <a-select-option value="от трех до пяти">
            от трех до пяти
          </a-select-option>
          <a-select-option value="более пяти лет">
            более пяти лет
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <div class="master__set__personal__data__label">Пароль</div>
      </a-form-item>

      <a-form-item>
        <a-input-password
          :value="personalForm.password"
          v-on:input="updateFormPassword($event)"
          placeholder="Пароль"
          size="large"
        />
      </a-form-item>
      <a-form-item>
        <a-input-password
          :value="personalForm.password_confirmation"
          v-on:input="updateFormPasswordConfirmation($event)"
          placeholder="Повторите Пароль"
          size="large"
        />
      </a-form-item>
      <a-form-item>
        <Button @click="sendNewForm" :buttonClass="'red-antd-button34'">Сохранить</Button>
      </a-form-item>
    </a-form>
    <a-modal :footer="null" :visible="isModalTariffVisible" @cancel="toggleTariffModal">
      <div class="tariff-modal-top">
        Привет! Твой тарифный план не позволяет откликаться на этот тендер, его нужно повысить
      </div>
      <div class="tariff-modal-buttons">
        <div @click="linkTariff">
          <Button buttonClass="white-antd-button-without-width">
            Изменить Тарифный план
          </Button>
        </div>
        <div @click="toggleTariffModal">
          <Button buttonClass="red-antd-button-without-width">
            Отмена
          </Button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>

// import InputTags from "@/components/common/InputTags.vue";
import Button from '@/components/common/Button.vue'
// import InputWorkExperience from "@/components/common/InputWorkExperience.vue";
import UploadAvatar from '@/components/common/UploadAvatar.vue'
import { getUUID, getBearer } from '@/helpers/authHelper'

export default {
  mounted () {
    this.$store.dispatch('setTags', this.$store.getters.getTags)
  },
  components: {
    Button,
    // InputWorkExperience,
    // InputTags,
    UploadAvatar
  },
  props: ['personalData'],
  data () {
    return {
      personalForm: {
        name: this.personalData.name,
        middlename: this.personalData.middlename,
        surname: this.personalData.surname,
        subtagsId: this.personalData.subtagsId,
        subtags: this.personalData.subtagsId,
        workExperience: this.personalData.workExperience,
        image: this.personalData.image,
        description: this.personalData.description,
        password: this.personalData.password,
        password_confirmation: this.personalData.password_confirmation
      },

      statusName: 'success',
      helpName: '',
      statusSurname: 'success',
      helpSurname: '',
      statusMiddlename: 'success',
      helpMiddlename: '',
      statusExepirience: 'success',
      helpExepirience: '',
      statusWorkProfile: 'success',
      helpWorkProfile: '',
      statusPassword: 'success',
      helpPassword: '',
      statusPasswordRepeat: 'success',
      helpPasswordRepeat: '',
      isModalTariffVisible: false,
    }
  },
  name: 'MasterSetPersonalData',
  methods: {
    toggleTariffModal() {
      this.isModalTariffVisible = !this.isModalTariffVisible;
    },
    unloadFile (file) {
      this.personalForm.image = file
    },
    getWorkTagsValue () {
      let arr = []
      this.$store.getters.getTags.map((object) => {
        let subtags = object.subtags
        for (var subtag of subtags) {
          arr.push(subtag)
        }
      })
      return arr
    },
    getCurrentWorkTagsValue () {
      let arr = []
      if (this.personalForm.subtags) {
        this.personalForm.subtags.map((subtag) => {
          arr.push(subtag)
        })
      }
      return arr
    },
    changeWorkProfile (workProfileValue) {
      this.personalForm.workProfileValue = workProfileValue
    },
    updateDescription (e) {
      this.personalForm.description = e.target.value
    },
    updateFormWorkProfile (value) {
      // this.personalForm.subtagsId = [value]
      this.isModalTariffVisible = !this.isModalTariffVisible;
      this.statusWorkProfile = 'success'
      this.helpWorkProfile = ''
    },

    updateFormName (e) {
      this.personalForm.name = e.target.value
      this.statusName = 'success'
      this.helpName = ''
    },
    updateFormSurname (e) {
      this.personalForm.surname = e.target.value
      this.statusSurname = 'success'
      this.helpSurname = ''
    },
    updateFormMiddlename (e) {
      this.personalForm.middlename = e.target.value
      this.statusMiddlename = 'success'
      this.helpMiddlename = ''
    },
    updateFormPassword (e) {
      this.personalForm.password = e.target.value
      this.statusPassword = 'success'
      this.helpPassword = ''
    },
    updateFormPasswordConfirmation (e) {
      this.personalForm.password_confirmation = e.target.value
      this.statusPasswordRepeat = 'success'
      this.helpPasswordRepeat = ''
    },
    updateFormExepirience (value) {
      this.personalForm.workExperience = value
      this.statusExepirience = 'success'
      this.helpExepirience = ''
    },

    linkTariff() {
      this.$router.push('/tariff')
    },

    sendNewForm () {
      if (this.personalForm.name === '') {
        this.statusName = 'error'
        this.helpName = 'Empty name'
      }
      if (this.personalForm.surname === '') {
        this.statusSurname = 'error'
        this.helpSurname = 'Empty Surname'
      }
      if (this.personalForm.middlename === '') {
        this.statusMiddlename = 'error'
        this.helpMiddlename = 'Empty Middlename'
      }
      if (this.personalForm.workProfileValue === '') {
        this.statusMiddlename = 'error'
        this.helpMiddlename = 'Empty Middlename'
      }

      if (this.personalForm.password !== this.personalForm.password_confirmation) {
        this.$notification.open({
          message: 'Пароли должны совпадать'
        })
      }

      if (
        this.statusName === 'success' &&
        this.statusSurname === 'success' &&
        this.statusMiddlename === 'success' &&
        this.personalForm.password === this.personalForm.password_confirmation
        // this.statusEmail == "success" &&
        // this.statusPassword == "success" &&
        // this.statusPasswordRepeat == "success" &&
      ) {
        let sendForm
        if (this.personalForm.description) {
          sendForm = this.personalForm
        } else {
          sendForm = {
            name: this.personalForm.name,
            middlename: this.personalForm.middlename,
            surname: this.personalForm.surname,
            subtagsId: this.personalForm.subtagsId,
            subtags: this.personalForm.subtagsId,
            workExperience: this.personalForm.workExperience,
            image: this.personalForm.image,
            password: this.personalForm.password
          }
        }
        const url = process.env.VUE_APP_SERVER_URL + `/users/${getUUID()}`

        fetch(url, {
          method: 'PUT',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getBearer()
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(sendForm)
        })
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            if (data.success) {
              this.$notification.open({
                message: 'Данные пользователя успешно обновлены!'
              })
            } else {
              this.$notification.open({
                message: 'Произошла ошибка обновленя данных',
                data
              })
            }
          })
      }
    }
  }
}
</script>

<style lang="scss">
.master__set__personal__data {
  width: 100%;
  height: 100%;

  padding: 50px 25% 0 33px;
  background-color: white;

  &__text-area{
    resize: none;
  }
  &__description-red {
  color: #b12021;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-right: 55px;
}
&__label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
}
@media (max-width: 992px) {
  .master__set__personal__data{
    padding-right: 10%;
  }
}
@media (max-width: 575px) {
  .master__set__personal__data{
    padding: 20px;
    &__description-red {
      margin-right: 0px;
    }
  }
}

.tariff-modal-top{
  margin-top: 20px;
}

.tariff-modal-buttons{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

</style>
