<template>
  <div class="review">
    <div>
      <Avatar :file="review.customer.image"></Avatar>
    </div>

    <div>
      <div class="review__labels">
        <div>
          <div>
            <p class="review__label__name">{{ review.customer.name }}</p>
            <p class="review__label__name">{{ review.customer.surname }}</p>
          </div>

          <p class="review__label__data">{{ review.updated_at }}</p>
        </div>

        <p class="review__label__data">{{ review.data }}</p>
      </div>
      <div class="review__desc">
        {{ review.text }}
      </div>
      <a-rate :default-value="review.rating" disabled></a-rate>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/common/Avatar.vue'
export default {
  props: ['review'],
  components: {
    Avatar
  }
}
</script>
<style lang="scss">
.review {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 12px;
  margin-bottom: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &__labels {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &__label__name {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    margin-right: 10px;
    margin-bottom: 0.2em;
  }
  &__label__data {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #989898;

    margin-bottom: 0.2em;
  }
  &__desc {
    //styleName: Body 14/regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}
</style>
